import { Link } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ROUTE_PATHS } from 'routes';
import logo from 'assets/img/logo-verisure.png'; // Ruta de la imagen


export default function Logo() {
  return (
    <Link component={NavLink} to={ROUTE_PATHS.PROPENSITY}>
      <img
        src={logo}
        alt="logo"
        style={
          {
            width: '195px',
            height: '60px',
            objectFit: 'contain',
            objectPosition: 'center',
            margin: '0 0 0 0',
          }
        }
      />
    </Link>
  );
}
