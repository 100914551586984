/* eslint-disable prettier/prettier */
import deepmerge from 'deepmerge';
import { createTheme } from '@mui/material';
import { cartoThemeOptions } from '@carto/react-ui';

const customTheme = {
    //class deck-tooltip bacjground color
    '& .deck-tooltip': {
        backgroundColor: '#fff',
    },
};

const theme = createTheme(deepmerge(cartoThemeOptions, customTheme));

export default theme;
