/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, Grid, ThemeProvider, StyledEngineProvider } from '@mui/material';
import { styled } from '@mui/material/styles';
import LazyLoadRoute from 'components/common/LazyLoadRoute';
import theme from './theme';
import routes from './routes';
import useAuth from './hooks/Auth0';
import { setUserId } from 'store/appSlice';
import { useDispatch } from 'react-redux';

const GridApp = styled(Grid)(() => ({
  flex: '1 1 auto',
  flexDirection: 'column',
  overflow: 'hidden',
}));

export default function App() {
  const routing = useRoutes(routes);
  const dispatch = useDispatch();
  useAuth();
  useEffect(() => {
    
    window.addEventListener('message', (event) => {
      if (event.origin !== window.location.origin) {
        const userId = event.data;

        if (userId) {
          dispatch(setUserId(userId.value));
        } else {
          dispatch(setUserId(0));
        }
      }      
    });
    
  }, []);


  return (
    <StyledEngineProvider injectFirst >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GridApp container id="container">
          <LazyLoadRoute>{routing}</LazyLoadRoute>
        </GridApp>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
