import { Grid, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink, useLocation } from 'react-router-dom';
import { ROUTE_PATHS } from 'routes';

const NavTabs = styled(Grid)(({ theme }) => ({
  flexDirection: 'row',
  backgroundColor: 'white',
  '& .MuiTab-root': {
    color: theme.palette.common.white,

    '&:hover': {
      borderBottomColor: theme.palette.common.black,
    },
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#f03',
    borderBottomColor: '#f03'
  },
}));

const VerticalNavTabs = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',

  '& .MuiTabs-root': {
    boxShadow: 'none',
  },
  '& .MuiTabs-vertical .MuiTab-root': {
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
  },
}));

export default function NavigationMenu({ column: vertical }) {
  const location = useLocation();

  const pathname = location.pathname.split('/')[1] || false;

  const WrapperTabs = ({ vertical = false, children }) =>
    vertical ? (
      <VerticalNavTabs container>{children}</VerticalNavTabs>
    ) : (
      <NavTabs container>{children}</NavTabs>
    );

  return (
    <WrapperTabs vertical={vertical}>
      <Tabs
        style={{
          height: '100%',
          marginTop: vertical && '100px',
        }}
        value={pathname}
        textColor={'primary'}
        orientation={vertical ? 'vertical' : 'horizontal'}
      >        {/* [hygen] Import links */}
        <Tab
          style={{ color: 'black', backgroundColor: 'white' }}  
          label='Propensity'
          value='propensity'
          component={NavLink}
          to={ROUTE_PATHS.PROPENSITY}
        />

      </Tabs>
    </WrapperTabs>
  );
}
