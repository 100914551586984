import { VOYAGER } from '@carto/react-basemaps';
import { API_VERSIONS } from '@deck.gl/carto';

export let initialState = {
  viewState: {
    latitude: 52.092876,
    longitude: 5.104480,
    zoom: 7,
    pitch: 0,
    bearing: 0,
    dragRotate: false,
  },
  basemap: VOYAGER,
  credentials: {
    apiVersion: API_VERSIONS.V3,
    apiBaseUrl: 'https://gcp-europe-west1.api.carto.com',
    accessToken:
      'eyJhbGciOiJIUzI1NiJ9.eyJhIjoiYWNfZ3M2Ym43aWEiLCJqdGkiOiI2ODQ4MTg0ZiJ9.0x_qNMKtlAA9hTTqokaB9PFk7WoO21OPEOgMFVVDjrE',
  },
  googleApiKey: '', // only required when using a Google Basemap
  googleMapId: '', // only required when using a Google Custom Basemap
  accountsUrl: 'http://app.carto.com/',

};
